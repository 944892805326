<template>
  <editor-pane class="code-pane" :visible="visible">
    <code-mirror :visible="visible" @updateDocument="$emit('updateDocument', $event)"/>
  </editor-pane>
</template>

<script>
import EditorPane from '@/components/EditorPane.vue'
import CodeMirror from '@/components/CodeMirror.vue'

export default {
  name: 'CodePane',
  components: { EditorPane, CodeMirror },
  props: { visible: Boolean },
}
</script>

<style lang="scss" scoped>
.code-pane {
  padding: 0;
}
</style>
