<template>
  <div class="code-editor">
    <div class="banner">
      <div class="error" v-if="error" tabindex="0">
        <div class="tag">Error:</div>
        <div class="message">{{ error.message }}</div>
        <div class="location">{{ getLocationString(error.location) }}</div>
      </div>
    </div>
    <div class="wrapper" ref="wrapper"></div>
  </div>
</template>

<script>
import CodeMirror from 'codemirror'
import '../clongdela.mode.js'

const parser = require('@clongdela/parser')

export default {
  name: 'CodeMirror',
  props: {
    visible: Boolean,
  },
  data () {
    return {
      editor: null,
      marks: [],
      error: null,
    }
  },
  computed: {
    code () {
      return this.$store.state.editor.code ?? this.$store.state.editor.document.code
    },
  },
  methods: {
    initEditor () {
      this.editor = CodeMirror(this.$refs.wrapper, {
        mode: null,
        lineNumbers: true,
        value: this.code ?? '',
      })

      this.editor.on('change', this.onChange)
    },
    refreshEditor () {
      this.editor.refresh()
    },
    onChange (instance) {
      const code = instance.getValue()
      this.$emit('updateDocument', { code })
      this.$store.commit('setCode', code)
      this.parse(code)
    },
    parse (code) {
      this.clearMarks()
      this.error = null
      try {
        const parsed = parser.parse(code)
        return parsed
      } catch (error) {
        this.error = error
        if (error instanceof parser.SyntaxError) {
          const { start, end } = error.location
          const from = { line: start.line - 1, ch: start.column - 1 }
          const to = { line: end.line - 1, ch: end.column - 1 }
          const mark = this.editor.markText(from, to, { className: 'syntax-error', title: error.message })
          this.marks.push(mark)
        } else {
          console.error(error)
        }
      }
    },
    clearMarks () {
      for (let i = this.marks.length - 1; i >= 0; i--) {
        try {
          this.marks[i].clear()
        } catch (error) {
          console.error('Error clearing mark', this.marks[i])
        }
        this.marks.splice(i, 1)
      }
    },
    getLocationString (location) {
      const { line, column } = location.start
      return `Ln ${line}, Col ${column}`
    },
  },
  watch: {
    visible: function (after) {
      if (!after) return
      this.$refs.wrapper.innerHTML = ''
      this.initEditor()
    },
  },
  mounted () {
    this.initEditor()
    this.editor.focus()
    this.parse(this.code ?? '')
  },
}
</script>

<style src="codemirror/lib/codemirror.css"></style>
<style lang="scss">

.code-editor {

  --c-bullet: var(--c-06);
  --c-separator: var(--c-txt-secondary);
  --c-word: var(--c-08);
  --c-paren: var(--c-txt-secondary);
  --c-ipa: var(--c-txt-emphasis);
  --c-delim: var(--c-01);
  --c-string: var(--c-01);
  --c-comment: var(--c-08);
  --c-category: var(--c-07);
  --c-mod-pos: var(--c-08);
  --c-mod-neg: var(--c-03);

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  main:not(.split) & {
    position: absolute;
  }

  .banner {
    width: auto;
    min-height: calc(1em + 10px);
    position: relative;

    .error {
      display: flex;
      background: var(--c-error);
      color: white;

      &:focus-within > .message,
      &:hover > .message {
        white-space: normal;
        overflow: visible;
        padding-bottom: 1rem;
      }

      > div {
        padding: 2px 0.5rem;
        white-space: nowrap;
      }

      .message {
        font-weight: 400;
        padding: 2px 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tag, .location {
        font-weight: 600;
        backdrop-filter: brightness(80%);
      }

      .location {
        margin-left: auto;
      }
    }
  }

  .wrapper,
  .CodeMirror {
    height: 100%;
  }

  .cm-s-default {
    background: var(--c-bg);
    color: var(--c-txt-primary);
    font-family: 'Source Code Pro', monospace;

    .CodeMirror-gutters {
      background: var(--c-bg-shade);
      border: none;
    }

    .CodeMirror-linenumber {
      color: var(--c-txt-secondary);
    }

    .CodeMirror-cursor {
      border-left: 2px solid var(--c-txt-primary);
    }

    .CodeMirror-selected {
      background: var(--c-bg-shade);
    }

    .CodeMirror-line {
      padding-left: 1rem;

      > span {
        span { color: var(--c-txt-primary); }

        .cm-comment { color: var(--c-comment) }
        .cm-bullet { color: var(--c-bullet) }
        .cm-separator { color: var(--c-separator) }
        .cm-word { color: var(--c-word) }
        .cm-paren { color: var(--c-paren) }
        .cm-ipa { color: var(--c-ipa) }
        .cm-delim { color: var(--c-delim) }
        .cm-string { color: var(--c-string) }
        .cm-category { color: var(--c-category) }
        .cm-mod-pos { color: var(--c-mod-pos) }
        .cm-mod-neg { color: var(--c-mod-neg) }

        .syntax-error {
          background: url(data:image/gif;base64,R0lGODlhBAADAHAAACH5BAEAAAIALAAAAAAEAAMAgQAAANwyLwAAAAAAAAIFlBOSagUAOw==) bottom repeat-x;

          &::before {
            content: '';
            position: absolute;
            height: 1.2rem;
            width: 3px;
            left: 0;
            background: var(--c-error);
            z-index: 100;
          }
        }
      }
    }
  }
}
</style>
