import CodeMirror from 'codemirror'
import 'codemirror/addon/mode/simple'

CodeMirror.defineSimpleMode('clongdela', {
  start: [
    { regex: /\/\/.*/, token: 'comment' },
    { regex: /(-)(\s*)/, token: ['bullet', null], push: 'wordDefinitionStart' },
    { regex: /(\$)(\s*)/, token: ['bullet', null], push: 'ruleDefinition' },
  ],
  wordDefinitionStart: [
    { regex: /[a-zA-Z0-9]+/, token: 'word', next: 'wordDefinition' },
  ],
  wordDefinition: [
    { regex: /(\s*)(:)(\s*)/, token: [null, 'separator', null] },
    { regex: /\[/, token: 'paren', push: 'ipa' },
    { regex: /.*(?:$)/, token: 'string', pop: true },
  ],
  ipa: [
    { regex: /]/, token: 'paren', pop: true },
    { regex: /[.,'"]/, token: 'delim' },
    { regex: /[^[\].,'"]+/, token: 'ipa' },
  ],
  ruleDefinition: [
    { regex: /\[/, token: 'paren', push: 'target' },
    { regex: /(\s*)(>)(\s*)(\[)/, token: [null, 'separator', null, 'paren'], push: 'ipa' },
    { regex: /(\s*)(\/)(\s*)/, token: [null, 'separator', null], next: 'condition' },
  ],
  target: [
    { regex: /(?![+-])[A-Za-z]+/, token: 'category' },
    { regex: /\+[A-Za-z]+/, token: 'mod-pos' },
    { regex: /-[A-Za-z]+/, token: 'mod-neg' },
    { regex: /]/, token: 'paren', pop: true },
  ],
  condition: [
    { regex: /_$/, token: 'separator', pop: true },
    { regex: /[^_]+$/, token: 'generic', pop: true },
    { regex: /_/, token: 'separator' },
    { regex: /[^_]+/, token: 'generic' },
  ],
})
